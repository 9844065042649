@import '../vendors/html5up-aerial/sass/main.scss';
@import '../vendors/html5up-aerial/sass/noscript.scss';

// @import "~vendors/html5up-aerial/css/main.css";
// @import "~vendors/html5up-aerial/css/noscript.css";
// // @import "~src/vendors/html5up-aerial/css/fontawesome.min.css";

body {
  &.plain {
    color: unset;
    font-size: unset;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.4em;
  }

  h4 {
    font-size: 1em;
  }

  .container {
    &.mantle {
      padding-top: 100;

      h2,
      h3,
      h4 {
        color: #6a6a6a;
      }
    }
  }

  .intl-tel-input {
    width: 100%;
    box-sizing: border-box;
  }

  #header {
    nav {
      a {
        -webkit-transition: border-color 0.2s ease-in-out;
        transition: border-color 0.2s ease-in-out;

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  // #wrapper {
  //   #main {
  //     color: #fff;
  //   }
  // }
}
